import produce from "immer";

const INITIAL_STATE = {
    attachment: {},
};

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case "@retailers_attachments/SETATTACHMENT":
                draft.attachment = action.payload
                break;
            default:
        }
    });
}

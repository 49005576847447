import React, {useEffect, useState} from "react";
import {Redirect, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {logout} from "../store/modules/auth/actions"
import * as Cookies from "js-cookie";
import {session_expired} from "../store/modules/auth/actions";
import {set_retailer} from "../store/modules/retailers/actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import {grey} from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Logo from "../images/logo.png";
import {authorization_headers} from "../utils/api_authorization";
import {decrypt} from "../utils/crypto_encryption";

const env = runtimeEnv();
const logo_path = '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        },
        background: {
            default: "red"
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    toolbar: {
        border: '1px solid',
        borderColor: grey[300],
    },
    iconButton: {
        padding: 10,
        color: 'white'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    logo: {
        height: '100px',
        width: '100px',
        backgroundRepeat: 'no-repeat',
        marginTop: '80px',
        backgroundImage: `url(${Logo})`,
    },
    orders_date: {
        color: grey[700],
        float: 'right'
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    remove_link_decoration: {
        color: 'inherit',
        textDecoration: 'inherit',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    bottom_buttons: {
        fontSize: '18px'
    },
});

function Home(props) {
    const {classes} = props
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [retailers, setRetailers] = useState([])

    const [user_menu_opened, openUserMenu] = React.useState(null)

    useEffect(() => {
        fetchRetailers()
    }, [])

    const fetchRetailers = () => {
        setLoading(true)

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/retailers",
            {
                headers: {...authorization_headers(), ...{Authorization: token}}
            })
            .then(res => res.text())
            .then(res => {
                let data = JSON.parse(decrypt(res))
                setRetailers(data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            });
    }

    const selectRetailer = (retailer) => {
        dispatch(set_retailer(retailer))
        history.push("/retailer")
    }

    const userMenuClicked = (event) => {
        openUserMenu(event.currentTarget);
    };

    const closeUserMenu = () => {
        openUserMenu(null);
    };

    const logOut = () => {
        Cookies.remove("AbsaAgiza")
        dispatch(logout())
        return <Redirect to="/login"/>
    };

    const userMenu = () => {
        return (
            <div>
                <IconButton
                    aria-controls="user-menu" aria-haspopup="true" className={classes.iconButton}
                    aria-label="directions" color="white" onClick={userMenuClicked}>
                    <MoreHorizIcon/>
                </IconButton>

                <Menu
                    id="user-menu"
                    anchorEl={user_menu_opened}
                    keepMounted
                    open={Boolean(user_menu_opened)}
                    onClose={closeUserMenu}>
                    <MenuItem onClick={logOut}>
                        <ExitToAppIcon className={classes.user_menu_icon}/>Logout
                    </MenuItem>
                </Menu>
            </div>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    const noRecords = () => {
        if (retailers.length <= 0 && loading === false) {
            return (
                <div>
                    <Container style={{marginTop: '100px', borderRadius: '0px'}}>
                        <Typography align="center">
                            No pending retailers.
                        </Typography>

                        <br/><br/><br/>
                        <Button variant="outlined" className={classes.bottom_buttons}
                                onClick={logOut} fullWidth>
                            Logout
                        </Button>
                    </Container>
                </div>
            )
        }
    }

    const retailersList = (classes) => {
        if (retailers.length > 0) {
            return (
                <List component="nav" style={{marginBottom: '60px', borderRadius: '0px'}}>
                    <ListItem className={classes.orders_items}>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography>
                                            <b>Name</b>
                                        </Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            className={classes.orders_date}>
                                            <b>Retailer ID</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    {retailers.map((retailer, index) => (
                        <ListItem
                            key={index} className={classes.orders_items}
                            onClick={() => selectRetailer(retailer)}
                            button>
                            <ListItemText
                                primary={
                                    <Grid container className={classes.root}>
                                        <Grid xs={6} item>
                                            <Typography>
                                                {retailer.business_name}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography
                                                className={classes.orders_date}>
                                                {retailer.keid}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }/>
                        </ListItem>
                    ))}
                </List>
            )
        }
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="primary" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        {userMenu()}
                        <Typography className={classes.input} align="center">
                            <b>Pending Retailers</b>
                        </Typography>
                        <Avatar src={logo_path} variant="square" className={classes.square}/>
                    </Toolbar>
                </AppBar>

                <Container style={{marginTop: '60px'}} fullWidth>
                    {noRecords()}
                    {retailersList(classes)}
                </Container>
                {progressLoader()}
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(Home);

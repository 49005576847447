import React from "react";
import {Link, Redirect} from "react-router-dom";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../images/logo.png'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import {useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Avatar from "@material-ui/core/Avatar";
import {Paper} from "@material-ui/core";

const bg_image_path = '/assets/images/bg_image.jpg'
const logo_path = '/assets/images/logo.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#af144b",
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});

const styles = theme => ({
    logo: {
        height: '100px',
        width: '100px',
        backgroundRepeat: 'no-repeat',
        marginTop: '200px',
        backgroundImage: `url(${Logo})`,
    },
    welcome_message: {
        color: 'white',
        fontSize: '32px'
    },
    welcome_message_sub: {
        color: 'white',
        fontSize: '20px'
    },
    page_container: {
        marginTop: '20px',
        paddingTop: '40px',
        backgroundColor: 'white',
        borderRadius: '8px'
    },
    primary_button: {
        background: '#640032',
        color: 'white',
        width: '100%'
    },
    primary_links: {
        color: '#F0325A',
        textDecoration: 'none',
    },
    iconButton: {
        padding: 10,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    no_decoration: {
        textDecoration: 'none',
        color: 'inherit !important'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function ForgotPin(props) {
    const {classes} = props
    const signed = useSelector(state => state.auth.signed)

    if (signed) {
        return <Redirect to="/"/>
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="white" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Avatar src={logo_path} variant="square" className={classes.square}/>
                    </Toolbar>
                </AppBar>
                <Paper style={{
                    background: `url(${bg_image_path})`, backgroundSize: "cover",
                    height: "100vh"
                }}>
                    <Grid container justify="center">
                        <Grid container style={{width: "95%", maxWidth: '400px', marginTop: '90px'}}>
                            <Grid container className={classes.root}>
                                <Grid xs={12}>
                                    <Grid container justify="center">
                                        <Grid/>
                                        <Grid>
                                            <Typography variant="h5" className={classes.welcome_message}>
                                                Welcome to Wezesha Agent
                                            </Typography>
                                            <Typography variant="h5" className={classes.welcome_message_sub}>
                                                Easy business customer onboarding.
                                            </Typography>
                                        </Grid>
                                        <Grid/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Container className={classes.page_container} fullWidth>
                                <Typography variant="body1" align="center">
                                    Please login to the backoffice portal and click your name on the right top corner to
                                    open a drop-down.
                                    Click 'Generate Agent App PIN' on the drop-down to generate a new PIN.
                                </Typography>
                                <br/><br/>
                                <Grid container justify="center">
                                    <Typography variant="body1">
                                        <Link to='/login' className={classes.primary_links}>Login</Link>
                                    </Typography>
                                </Grid>
                                <br/>
                                <Grid container justify="center">
                                    <Typography variant="caption">
                                        Powered by <a
                                        href='https://agiza.io/'
                                        target="_blank"
                                        className={classes.primary_links}>
                                        Agiza.io
                                    </a>
                                    </Typography>
                                </Grid>
                                <br/>
                            </Container>
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(ForgotPin);

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from '@material-ui/core/Grid';
import Divider from "@material-ui/core/Divider";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Cookies from "js-cookie";
import {session_expired} from "../store/modules/auth/actions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {authorization_headers} from "../utils/api_authorization";
import {decrypt} from "../utils/crypto_encryption";

const env = runtimeEnv();
const logo_path = '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    iconButton: {
        padding: 10,
        color: 'white'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    orders_date: {
        color: grey[700],
        float: 'right'
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    success_btn: {
        backgroundColor: "#00b89f",
        color: grey[50],
        borderRadius: "20px",
        fontSize: '12px',
    },
    closed_btn: {
        backgroundColor: grey[500],
        borderRadius: "20px",
        fontSize: '12px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    bottom_buttons: {
        fontSize: '18px'
    },
});

function Retailer(props) {
    const retailer = useSelector(state => state.retailers.retailer)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const {classes} = props;
    const [retailer_data, setRetailerData] = useState({})

    useEffect(() => {
        fetchRetailers()
    }, [])

    const fetchRetailers = () => {
        setLoading(true)

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/retailers/" + retailer.id,
            {
                headers: {...authorization_headers(), ...{Authorization: token}}
            })
            .then(res => res.text())
            .then(res => {
                let data = JSON.parse(decrypt(res))
                setRetailerData(data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            });
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }


    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="primary" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Link to='/' className={classes.no_decoration}>
                            <IconButton className={classes.iconButton} aria-label="directions"
                                        disableRipple>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Link>
                        <Typography className={classes.input} align="center">
                            <b>{retailer_data.business_name} Profile</b>
                        </Typography>
                        <Link to='/' className={classes.no_decoration}>
                            <Avatar src={logo_path} variant="square" className={classes.square}/>
                        </Link>
                    </Toolbar>
                </AppBar>

                <Container style={{marginTop: '90px'}} fullWidth>
                    <Grid container className={classes.bottom_buttons}>
                        <Grid xs={6} item>
                            <Link to='/edit_retailer' className={classes.no_decoration}>
                                <Button variant="contained"
                                        style={{backgroundColor: '#640032', color: 'white', width: '95%'}}
                                        className={classes.bottom_buttons}>
                                    Edit Profile
                                </Button>
                            </Link>
                        </Grid>
                        <Grid xs={6} item>
                            <Link to='/retailer_attachments' className={classes.no_decoration}>
                                <Button variant="contained"
                                        style={{
                                            backgroundColor: '#640032',
                                            color: 'white',
                                            width: '95%',
                                            float: "right"
                                        }}
                                        className={classes.bottom_buttons}>
                                    Attachments
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>

                <List component="nav" style={{marginBottom: '60px', borderRadius: '0px'}}>
                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1">
                                            <b>Business name</b>
                                        </Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.business_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Phone number</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.phone_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Retailer ID</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.keid}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>National ID number</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.national_id_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>First name</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.first_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Last name</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.last_name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Email address</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.email_address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Business registration number</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.business_registration_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Registered office address</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.registered_office_address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Trading address</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.trading_address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Branches countries</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.branches_countries}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Postal address</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.postal_address}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Postal code</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.postal_code}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Source of wealth</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.source_of_wealth}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Source of funds</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.source_of_funds}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>Expected annual turnover</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.expected_annual_turnover}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>

                    <Divider/>

                    <Divider/>

                    <ListItem>
                        <ListItemText
                            primary={
                                <Grid container className={classes.root}>
                                    <Grid xs={6} item>
                                        <Typography variant="body1"><b>KRA PIN number</b></Typography>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <Typography
                                            variant="body1"
                                            className={classes.orders_date}>
                                            {retailer_data.kra_pin_number}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }/>
                    </ListItem>
                </List>
                {progressLoader()}
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(Retailer);


import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import {session_expired} from "../store/modules/auth/actions";
import InputBase from "@material-ui/core/InputBase";
import * as Cookies from "js-cookie";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {authorization_headers} from "../utils/api_authorization";
import {encrypt} from "../utils/crypto_encryption";

const env = runtimeEnv();
const logo_path = '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    root: {
        flexGrow: 1,
    },
    iconButton: {
        padding: 10,
        color: 'white'
    },
    page_container: {
        marginTop: '90px',
        borderRadius: '8px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    input_label: {
        marginBottom: '2px'
    },
    orders_date: {
        color: grey[700],
        float: 'right'
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    success_btn: {
        backgroundColor: "#00b89f",
        color: grey[50],
        borderRadius: "20px",
        fontSize: '12px',
    },
    closed_btn: {
        backgroundColor: grey[500],
        borderRadius: "20px",
        fontSize: '12px',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    fields_toolbar: {
        borderColor: '#C9C9C9',
        border: '1px solid',
        borderRadius: '4px',
        paddingLeft: '8px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    },
});

function EditRetailer(props) {
    const retailer = useSelector(state => state.retailers.retailer)
    const {classes} = props;
    const history = useHistory();
    const [state, setState] = useState(new Map())
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)
    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)

    const upsert = (key, value) => {
        setState((prev) => new Map(prev).set(key, value));
    }

    const handleChange = (event) => {
        const {name, value} = event.target
        upsert(name, value)
    }

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time))
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let params = {
            retailer: {
                business_name: state.get('business_name'),
                phone_number: state.get('phone_number'),
                keid: state.get('keid'),
                national_id_number: state.get('national_id_number'),
                first_name: state.get('first_name'),
                last_name: state.get('last_name'),
                email_address: state.get('email_address'),
                business_registration_number: state.get('business_registration_number'),
                registered_office_address: state.get('registered_office_address'),
                trading_address: state.get('trading_address'),
                branches_countries: state.get('branches_countries'),
                postal_address: state.get('postal_address'),
                postal_code: state.get('postal_code'),
                source_of_wealth: state.get('source_of_wealth'),
                source_of_funds: state.get('source_of_funds'),
                expected_annual_turnover: state.get('expected_annual_turnover'),
                kra_pin_number: state.get('kra_pin_number'),
            }
        }

        setLoading(true)

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/retailers/" + retailer.id,
            {
                method: "PUT",
                headers: {...authorization_headers(), ...{Authorization: token}},
                body: encrypt(JSON.stringify(params)),
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        setSnackbarMessage("Retailer information updated successfully.")
                        setSnackbarSeverity("success")
                        openSnackbar(true)

                        sleep(5000).then(() => {
                            history.push("/retailer")
                        })
                    } else {
                        setSnackbarMessage("Error submitting information. Please retry or contact support.")
                        openSnackbar(true)
                        setLoading(false)
                    }
                }).catch(() => {
                    setSnackbarMessage("Error submitting information. Please retry or contact support.")
                    openSnackbar(true)
                    setLoading(false)
                })
            })
            .catch(() => {
                setSnackbarMessage("Error submitting information. Please retry or contact support.")
                openSnackbar(true)
                setLoading(false)
            })
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="primary" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Link to='/retailer' className={classes.no_decoration}>
                            <IconButton className={classes.iconButton} aria-label="directions"
                                        disableRipple>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Link>
                        <Typography className={classes.input} align="center">
                            <b>Edit {retailer.business_name} profile</b>
                        </Typography>
                        <Link to='/' className={classes.no_decoration}>
                            <Avatar src={logo_path} variant="square" className={classes.square}/>
                        </Link>
                    </Toolbar>
                </AppBar>

                <Container className={classes.page_container} fullWidth>
                    <form onSubmit={handleSubmit}>
                        <Typography variant="body1" className={classes.input_label}>
                            Business name
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Business name"
                                name="business_name"
                                value={state.get('business_name') || retailer.business_name}
                                onChange={handleChange}
                            />
                        </Toolbar>
                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Phone number
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter phone number"
                                name="phone_number"
                                value={state.get('phone_number') || retailer.phone_number}
                                type="phone_number"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            National ID number
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter national ID number"
                                name="national_id_number"
                                value={state.get('phone_number') || retailer.phone_number}
                                type="national_id_number"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            First name
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter first name"
                                name="first_name"
                                value={state.get('first_name') || retailer.first_name}
                                type="first_name"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Last name
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter last name"
                                name="last_name"
                                value={state.get('last_name') || retailer.last_name}
                                type="last_name"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Email address
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter email address"
                                name="email_address"
                                value={state.get('email_address') || retailer.email_address}
                                type="email_address"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Business registration number
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter business registration number"
                                name="business_registration_number"
                                value={state.get('business_registration_number') || retailer.business_registration_number}
                                type="business_registration_number"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Registered office address
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter registered office address"
                                name="registered_office_address"
                                value={state.get('registered_office_address') || retailer.registered_office_address}
                                type="registered_office_address"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Trading address
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter trading address"
                                name="trading_address"
                                value={state.get('trading_address') || retailer.trading_address}
                                type="trading_address"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Branches' countries
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter branches countries"
                                name="branches_countries"
                                value={state.get('branches_countries') || retailer.branches_countries}
                                type="branches_countries"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Postal address
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter postal address"
                                name="postal_address"
                                value={state.get('postal_address') || retailer.postal_address}
                                type="postal_address"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Postal code
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter postal code"
                                name="postal_code"
                                value={state.get('postal_code') || retailer.postal_code}
                                type="postal_code"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Source of wealth
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter source of wealth"
                                name="source_of_wealth"
                                value={state.get('source_of_wealth') || retailer.source_of_wealth}
                                type="source_of_wealth"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Source of funds
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter source of funds"
                                name="source_of_funds"
                                value={state.get('source_of_funds') || retailer.source_of_funds}
                                type="source_of_funds"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            KRA PIN
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter KRA PIN"
                                name="kra_pin_number"
                                value={state.get('kra_pin_number') || retailer.kra_pin_number}
                                type="kra_pin_number"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Expected annual turnover
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputBase
                                className={classes.input}
                                placeholder="Enter expected annual turnover"
                                name="expected_annual_turnover"
                                value={state.get('expected_annual_turnover') || retailer.expected_annual_turnover}
                                type="expected_annual_turnover"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Grid container justify="center">
                            <Button variant="contained" align="center" size="large"
                                    fullWidth={true}
                                    style={{
                                        backgroundColor: '#640032', color: 'white',
                                        fontSize: '18px'
                                    }}
                                    type="submit"
                                    className={classes.order_now_btn}><b>Update</b>
                            </Button>
                        </Grid>
                    </form>
                </Container>

                {snackbarLoader()}
                {progressLoader()}
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(EditRetailer);


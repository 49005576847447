import React from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Cookies from "js-cookie";
import {session_expired} from "../store/modules/auth/actions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {saveAs} from 'file-saver';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {authorization_headers} from "../utils/api_authorization";

const env = runtimeEnv();
const logo_path = '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    root: {
        flexGrow: 1,
    },
    iconButton: {
        padding: 10,
        color: 'white'
    },
    page_container: {
        marginTop: '90px',
        borderRadius: '8px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    orders_date: {
        color: grey[700],
        float: 'right'
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    success_btn: {
        backgroundColor: "#00b89f",
        color: grey[50],
        borderRadius: "20px",
        fontSize: '12px',
    },
    closed_btn: {
        backgroundColor: grey[500],
        borderRadius: "20px",
        fontSize: '12px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    bottom_buttons: {
        fontSize: '12px'
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    },
});

function RetailerAttachment(props) {
    const retailer = useSelector(state => state.retailers.retailer)
    const attachment = useSelector(state => state.retailers_attachments.attachment)
    const dispatch = useDispatch()
    const {classes} = props;
    const history = useHistory();

    const [loading, setLoading] = React.useState(false)
    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)

    const downloadAttachment = () => {
        saveAs(attachment.image_url, attachment.attachment_type) // Put your image url he
    }

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time))
    }

    const deleteAttachment = () => {
        setLoading(true)

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/retailers/" + retailer.id +
            "/delete_attachment?attachment_id=" + attachment.id,
            {
                method: "DELETE",
                headers: {...authorization_headers(), ...{Authorization: token}},
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        setSnackbarMessage("Attachment successfully deleted.")
                        setSnackbarSeverity("success")
                        openSnackbar(true)

                        sleep(5000).then(() => {
                            history.push("/retailer_attachments")
                        })
                    } else {
                        setSnackbarMessage("Error deleting attachment. Please retry or contact support.")
                        openSnackbar(true)
                        setLoading(false)
                    }
                }).catch(() => {
                    setSnackbarMessage("Error deleting attachment. Please retry or contact support.")
                    openSnackbar(true)
                    setLoading(false)
                })
            })
            .catch(() => {
                setSnackbarMessage("Error deleting attachment. Please retry or contact support.")
                openSnackbar(true)
                setLoading(false)
            })
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="primary" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Link to='/retailer_attachments' className={classes.no_decoration}>
                            <IconButton className={classes.iconButton} aria-label="directions"
                                        disableRipple>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Link>
                        <Typography className={classes.input} align="center">
                            <b>Attachment: {attachment.attachment_type}</b>
                        </Typography>
                        <Link to='/' className={classes.no_decoration}>
                            <Avatar src={logo_path} variant="square" className={classes.square}/>
                        </Link>
                    </Toolbar>
                </AppBar>
                <br/><br/>

                <Container className={classes.page_container} fullWidth>
                    <Grid container className={classes.bottom_buttons}>
                        <Grid xs={6} item>
                            <Button variant="contained"
                                    style={{
                                        backgroundColor: '#640032', color: 'white', width: '95%',
                                        fontSize: '18px'
                                    }}
                                    className={classes.bottom_buttons}
                                    onClick={() => downloadAttachment()}>
                                Download
                            </Button>
                        </Grid>
                        <Grid xs={6} item>
                            <Button variant="contained"
                                    style={{
                                        backgroundColor: '#640032', color: 'white', float: "right", width: '95%',
                                        fontSize: '18px'
                                    }}
                                    className={classes.bottom_buttons}
                                    onClick={() => deleteAttachment()}>
                                Delete
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <Container>
                    <br/><br/>
                    <img src={attachment.image_url} alt={attachment.attachment_type}
                         style={{
                             width: '100%', height: undefined, aspectRatio: 1
                         }}/>
                </Container>

                {snackbarLoader()}
                {progressLoader()}
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(RetailerAttachment);


import React from "react";
import {Route, BrowserRouter, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import Home from "./home/Home";
import Retailer from "./retailers/Retailer";
import EditRetailer from "./retailers/EditRetailer";
import RetailerAttachments from "./retailers/RetailerAttachments";
import AddRetailerAttachment from "./retailers/AddAttachments";
import RetailerAttachment from "./retailers/RetailerAttachment";
import Login from "./login/Login";
import LoginOtp from "./login/LoginOtp";
import ForgotPin from "./login/ForgotPin";
import ProtectedRoute from './utils/ProtectedRoute'
import "@fontsource/source-sans-pro";

export function Main() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <div>
                    <div className="content">
                        <Switch>
                            <ProtectedRoute exact path="/" component={Home}/>
                            <ProtectedRoute exact path="/retailer" component={Retailer}/>
                            <ProtectedRoute exact path="/edit_retailer" component={EditRetailer}/>
                            <ProtectedRoute exact path="/retailer_attachments" component={RetailerAttachments}/>
                            <ProtectedRoute exact path="/add_retailer_attachment"
                                            component={AddRetailerAttachment}/>
                            <ProtectedRoute exact path="/retailer_attachment" component={RetailerAttachment}/>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/login_otp" component={LoginOtp}/>
                            <Route exact path="/forgot_pin" component={ForgotPin}/>
                        </Switch>
                    </div>
                </div>
            </BrowserRouter>
        </Provider>
    )
}

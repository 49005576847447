export function login(agent_data) {
    return {
        type: "@auth/LOGIN",
        payload: agent_data
    };
}

export function logout() {
    return {
        type: "@auth/LOGOUT"
    };
}

export function session_expired() {
    return {
        type: "@auth/SESSION_EXPIRED"
    };
}

export function update_retailer(agent_data) {
    return {
        type: "@auth/UPDATE_RETAILER",
        payload: agent_data
    };
}


import { combineReducers } from "redux";
import auth from "./auth/reducers"
import count from "./count/reducers";
import retailers from "./retailers/reducers"
import retailers_attachments from "./retailers_attachments/reducers"

export default combineReducers({
    auth,
    count,
    retailers,
    retailers_attachments
});

import React, {useState, Fragment} from "react";
import {Link, Redirect, useHistory} from "react-router-dom";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import InputBase from "@material-ui/core/InputBase";
import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import runtimeEnv from '@mars/heroku-js-runtime-env';
import {useDispatch, useSelector} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import {login, update_retailer} from "../store/modules/auth/actions";
import * as Icon from 'react-feather';
import Divider from '@material-ui/core/Divider';
import InputAdornment from "@material-ui/core/InputAdornment";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import {Paper} from "@material-ui/core";
import * as Cookies from 'js-cookie';
import {authorization_headers} from "../utils/api_authorization";
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {appStoreGet, appStoreRemove} from "../utils/app_storage";

const env = runtimeEnv();
const bg_image_path = '/assets/images/bg_image.jpg'
const logo_path = '/assets/images/logo.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    welcome_message: {
        color: 'white',
        fontSize: '32px'
    },
    welcome_message_sub: {
        color: 'white',
        fontSize: '20px'
    },
    logo: {
        height: '100px',
        width: '100px',
        backgroundRepeat: 'no-repeat',
        marginTop: '200px',
        backgroundImage: `url(${logo_path})`,
    },
    page_container: {
        marginTop: '20px',
        paddingTop: '40px',
        backgroundColor: 'white',
        borderRadius: '8px'
    },
    fields_toolbar: {
        borderColor: '#C9C9C9',
        border: '1px solid',
        borderRadius: '4px',
        paddingLeft: '8px',
    },
    iconButton: {
        padding: 10,
    },
    input_label: {
        marginBottom: '2px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        '& .MuiInputBase-input::placeholder': {
            fontStyle: 'italic'
        }
    },
    primary_button: {
        background: '#640032',
        color: 'white',
        width: '100%'
    },
    primary_links: {
        color: '#F0325A',
        textDecoration: 'none',
    },
    disclaimer_msg: {
        paddingTop: '10px',
        textAlign: 'center',
        fontSize: '0.75rem'
    },
    disclaimer_checkbox_msg: {
        paddingTop: '5px',
        textAlign: 'center',
    },
    disclaimer_checkbox: {
        marginTop: '-5px'
    },
    no_decoration: {
        textDecoration: 'none',
        color: 'inherit !important'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    }
});

function LoginOtp(props) {
    const {classes} = props
    const history = useHistory()
    const dispatch = useDispatch()
    const [agent_id] = React.useState(appStoreGet('agent_id'))
    const [allow_agent_otp_login] = React.useState(appStoreGet('allow_agent_otp_login'))
    const signed = useSelector(state => state.auth.signed)

    const [otp, setOtp] = useState(null)

    const [loading, setLoading] = React.useState(false)
    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)

    if (signed) {
        return <Redirect to="/"/>
    }

    if (allow_agent_otp_login !== 'true' || agent_id === null) {
        return <Redirect to="/"/>
    }

    const setCookie = (token) => {
        // Cookie should exipire in 5 minutes
        var expite_at = new Date(new Date().getTime() + 5 * 60 * 1000)
        Cookies.remove('AbsaAgiza')
        Cookies.set('AbsaAgiza', token, {expires: expite_at, secure: true})
    }


    const handleChange = (event) => {
        const {value} = event.target
        setOtp(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/login/validate_otp",
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify({agent_id: agent_id, otp: otp})),
            })
            .then(res => {
                    res.text().then(text => {
                        if (res.ok) {
                            let data = JSON.parse(decrypt(text))
                            let agent_data = data.agent
                            dispatch(login(agent_data))
                            setCookie(data.token)
                            history.push("/")

                            appStoreRemove('allow_agent_otp_login')
                            appStoreRemove('agent_id')
                        } else {
                            setSnackbarMessage("Invalid or expired OTP. Please try again or go back to login page.")
                            openSnackbar(true)
                            appStoreRemove('allow_agent_otp_login')
                            appStoreRemove('agent_id')
                        }

                        setLoading(false)
                    }).catch(() => {
                        setSnackbarMessage("Invalid or expired OTP. Please try again or go back to login page.")
                        openSnackbar(true)
                        setLoading(false)

                        appStoreRemove('allow_agent_otp_login')
                        appStoreRemove('agent_id')
                    });
                }
            )

            .catch(() => {
                setSnackbarMessage("Invalid or expired OTP. Please try again or go back to login page.")
                openSnackbar(true)
                setLoading(false)

                appStoreRemove('allow_agent_otp_login')
                appStoreRemove('agent_id')
            });
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    return (
        <Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="white" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Avatar src={logo_path} variant="square" className={classes.square}/>
                    </Toolbar>
                </AppBar>
                <Paper style={{
                    background: `url(${bg_image_path})`, backgroundSize: "cover",
                    height: "100vh"
                }}>
                    <Grid container justify="center">
                        <Grid container style={{width: "95%", maxWidth: '400px', marginTop: '90px'}}>
                            <Grid container className={classes.root}>
                                <Grid xs={12}>
                                    <Grid container justify="center">
                                        <Grid/>
                                        <Grid>
                                            <Typography variant="h5" className={classes.welcome_message}>
                                                Welcome to Wezesha Stock
                                            </Typography>
                                            <Typography variant="h5" className={classes.welcome_message_sub}>
                                                Order stock anytime. Get instant financing.
                                            </Typography>
                                        </Grid>
                                        <Grid/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Container className={classes.page_container} fullWidth>
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="body1" className={classes.input_label}>
                                        SMS OTP
                                    </Typography>
                                    <Toolbar className={classes.fields_toolbar}>
                                        <InputAdornment position="start">
                                            <Icon.Hash style={{color: '#a19f9f'}}/>
                                        </InputAdornment>
                                        <Divider orientation="vertical" flexItem/>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Enter OTP sent via SMS"
                                            name="otp"
                                            value={otp}
                                            onChange={handleChange}
                                        />
                                    </Toolbar>
                                    <br/>
                                    <Grid container justify="center">
                                        <Button variant="contained" align="center" size="large"
                                                fullWidth={true} color="primary"
                                                type="submit"
                                                className={classes.primary_button}><b>Submit</b>
                                        </Button>
                                    </Grid>
                                </form>
                                <br/>
                                <Grid container justify="center" style={{marginTop: '7px'}}>
                                    <Typography variant="body1">
                                        <Link to='/login' className={classes.primary_links}>Login</Link>
                                    </Typography>
                                </Grid>
                                <br/>
                                <Grid container justify="center">
                                    <Typography variant="caption">
                                        Powered by <a
                                        href='https://agiza.io/'
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.primary_links}>
                                        Agiza.io
                                    </a>
                                    </Typography>
                                </Grid>
                                <br/>
                            </Container>

                            {snackbarLoader()}
                            {progressLoader()}
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </Fragment>
    )
}

export default withStyles(styles)(LoginOtp);

import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from '@material-ui/core/Grid';
import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Cookies from "js-cookie";
import {session_expired} from "../store/modules/auth/actions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {set_attachment} from "../store/modules/retailers_attachments/actions";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {authorization_headers} from "../utils/api_authorization";
import {decrypt} from "../utils/crypto_encryption";

const env = runtimeEnv();
const logo_path = '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    primary_button: {
        background: '#640032',
        color: 'white',
        width: '100%',
        fontSize: '18px'
    },
    iconButton: {
        padding: 10,
        color: 'white'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    orders_date: {
        color: grey[700],
        float: 'right'
    },
    page_container: {
        marginTop: '20px',
        paddingTop: '80px',
        backgroundColor: 'white',
        borderRadius: '8px'
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    success_btn: {
        backgroundColor: "#00b89f",
        color: grey[50],
        borderRadius: "20px",
        fontSize: '12px',
    },
    closed_btn: {
        backgroundColor: grey[500],
        borderRadius: "20px",
        fontSize: '12px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    bottom_buttons: {
        fontSize: '12px'
    },
});

function RetailerAttachments(props) {
    const retailer = useSelector(state => state.retailers.retailer)
    const {classes} = props;
    const history = useHistory()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [attachments, setAttachments] = useState([])

    useEffect(() => {
        fetchAttachments()
    }, [])

    const fetchAttachments = () => {
        setLoading(true)

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/retailers/" + retailer.id + "/attachments",
            {
                headers: {...authorization_headers(), ...{Authorization: token}}
            })
            .then(res => res.text())
            .then(res => {
                let data = JSON.parse(decrypt(res))
                setAttachments(data)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            });
    }

    const selectAttachment = (attachment) => {
        dispatch(set_attachment(attachment))
        history.push("/retailer_attachment")
    }

    const noRecords = () => {
        if (attachments.length <= 0 && loading === false) {
            return (
                <div>
                    <Container style={{marginTop: '100px', borderRadius: '0px'}}>
                        <Typography align="center" variant="subtitle1">
                            No attachments.
                        </Typography>
                    </Container>
                </div>
            )
        }
    }

    const attachmentsList = (classes) => {
        if (attachments.length > 0) {
            return (
                <List component="nav" style={{marginBottom: '60px', borderRadius: '0px'}}>
                    {attachments.map((attachment, index) => (
                        <ListItem
                            key={index} className={classes.orders_items}
                            onClick={() => selectAttachment(attachment)}
                            button>
                            <ListItemText
                                primary={
                                    <Grid container className={classes.root}>
                                        <Grid xs={6} item>
                                            <Typography variant="h6">
                                                {attachment.attachment_type}
                                            </Typography>
                                        </Grid>
                                        <Grid xs={6} item>
                                            <Typography
                                                variant="h6"
                                                className={classes.orders_date}>
                                                {/*{retailer.keid}*/}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }/>
                        </ListItem>
                    ))}
                </List>
            )
        }
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }


    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="primary" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Link to='/retailer' className={classes.no_decoration}>
                            <IconButton className={classes.iconButton} aria-label="directions"
                                        disableRipple>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Link>
                        <Typography className={classes.input} align="center">
                            <b>Attachments for {retailer.business_name}</b>
                        </Typography>
                        <Link to='/' className={classes.no_decoration}>
                            <Avatar src={logo_path} variant="square" className={classes.square}/>
                        </Link>
                    </Toolbar>
                </AppBar>

                <Container style={{marginTop: '90px'}} fullWidth>
                    {noRecords()}
                    {attachmentsList(classes)}
                </Container>

                <br/>

                <Grid container justify="center">
                    <Link to='/add_retailer_attachment' className={classes.no_decoration}
                          style={{width: '80%'}}>
                        <Button variant="contained"
                                className={classes.primary_button}
                                onClick={() => ''}>
                            Add Attachment
                        </Button>
                    </Link>
                </Grid>

                {progressLoader()}
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(RetailerAttachments);


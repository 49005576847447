import React, {useState} from "react";
import {Link, Redirect} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Logo from '../images/logo.png'
import Grid from "@material-ui/core/Grid";
import Container from '@material-ui/core/Container';
import InputBase from "@material-ui/core/InputBase";
import Button from '@material-ui/core/Button';
import Toolbar from "@material-ui/core/Toolbar";
import runtimeEnv from '@mars/heroku-js-runtime-env';
import Typography from "@material-ui/core/Typography";
import * as Cookies from 'js-cookie'
import {login} from "../store/modules/auth/actions"
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Icon from 'react-feather';
import Divider from '@material-ui/core/Divider';
import {Paper} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import {authorization_headers} from "../utils/api_authorization";
import {encrypt, decrypt} from "../utils/crypto_encryption";
import {appStoreSet} from "../utils/app_storage";

const env = runtimeEnv();
const bg_image_path = '/assets/images/bg_image.jpg'
const logo_path = '/assets/images/logo.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
    },
});


function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    welcome_message: {
        color: 'white',
        fontSize: '32px'
    },
    welcome_message_sub: {
        color: 'white',
        fontSize: '20px'
    },
    logo: {
        height: '100px',
        width: '100px',
        backgroundRepeat: 'no-repeat',
        marginTop: '80px',
        backgroundImage: `url(${Logo})`,
    },
    page_container: {
        marginTop: '20px',
        paddingTop: '40px',
        backgroundColor: 'white',
        borderRadius: '8px'
    },
    primary_button: {
        background: '#640032',
        color: 'white',
        width: '100%'
    },
    primary_links: {
        color: '#F0325A',
        textDecoration: 'none',
    },
    fields_toolbar: {
        borderColor: '#C9C9C9',
        border: '1px solid',
        borderRadius: '4px',
        paddingLeft: '8px',
    },
    iconButton: {
        padding: 10,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        '& .MuiInputBase-input::placeholder': {
            fontStyle: 'italic'
        }
    },
    input_label: {
        marginBottom: '2px'
    },
    order_now_btn: {
        width: '60%',
    },
    no_decoration: {
        textDecoration: 'none',
        color: 'inherit !important'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    },
});

function Login(props) {
    const signed = useSelector(state => state.auth.signed)
    const dispatch = useDispatch()

    const {classes, history} = props
    const [state, setState] = useState(new Map())

    const [loading, setLoading] = React.useState(false)
    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)

    if (signed) {
        return <Redirect to="/"/>
    }

    const upsert = (key, value) => {
        setState((prev) => new Map(prev).set(key, value));
    }

    const handleChange = (event) => {
        const {name, value} = event.target
        upsert(name, value)
    }

    const setCookie = (token) => {
        // Cookie should exipire in 5 minutes
        var expite_at = new Date(new Date().getTime() + 5 * 60 * 1000)
        Cookies.remove('AbsaAgiza')
        Cookies.set('AbsaAgiza', token, {expires: expite_at, secure: true})
    }

    const isValidated = () => {
        if (!state.get('email_address')) {
            setSnackbarMessage("Email address cannot be blank.")
            openSnackbar(true)
            return false
        } else if (!state.get('pin')) {
            setSnackbarMessage("Pin cannot be blank.")
            openSnackbar(true)
            return false
        }

        return true
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (!isValidated()) {
            return
        }

        let params = {
            email_address: state.get('email_address'),
            pin: state.get('pin'),
        }

        setLoading(true)

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/login",
            {
                method: "POST",
                headers: authorization_headers(),
                body: encrypt(JSON.stringify(params)),
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        let data = JSON.parse(decrypt(text))

                        if (data.require_otp === true) {
                            appStoreSet("allow_agent_otp_login", true)
                            appStoreSet("agent_id", data.agent_id)
                            history.push("/login_otp")
                        } else {
                            let agent_data = data.agent
                            dispatch(login(agent_data))

                            setCookie(data.token)
                            history.push("/")
                        }
                    } else {
                        setSnackbarMessage("Invalid email address or PIN")
                        setSnackbarSeverity("warning")
                        openSnackbar(true)
                        // dispatch(login({}))
                    }
                    setLoading(false)
                }).catch(() => {
                    setSnackbarMessage("Error submitting information. Please retry or contact support.1")
                    openSnackbar(true)
                    setLoading(false)
                })
            })
            .catch(() => {
                setSnackbarMessage("Error submitting information. Please retry or contact support.2")
                openSnackbar(true)
                setLoading(false)
            })
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="white" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Avatar src={logo_path} variant="square" className={classes.square}/>
                    </Toolbar>
                </AppBar>
                <Paper style={{
                    background: `url(${bg_image_path})`, backgroundSize: "cover",
                    height: "100vh"
                }}>
                    <Grid container justify="center">
                        <Grid container style={{width: "95%", maxWidth: '400px', marginTop: '90px'}}>
                            <Grid container className={classes.root}>
                                <Grid xs={12}>
                                    <Grid container justify="center">
                                        <Grid/>
                                        <Grid>
                                            <Typography variant="h5" className={classes.welcome_message}>
                                                Welcome to Wezesha Agent
                                            </Typography>
                                            <Typography variant="h5" className={classes.welcome_message_sub}>
                                                Easy business customer onboarding.
                                            </Typography>
                                        </Grid>
                                        <Grid/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Container className={classes.page_container} fullWidth>
                                <form onSubmit={handleSubmit}>
                                    <Typography variant="body1" className={classes.input_label}>
                                        Email address
                                    </Typography>
                                    <Toolbar className={classes.fields_toolbar}>
                                        <InputAdornment position="start">
                                            <Icon.AtSign style={{color: '#a19f9f'}}/>
                                        </InputAdornment>
                                        <Divider orientation="vertical" flexItem/>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Enter email address"
                                            name="email_address"
                                            value={state.get('email_address')}
                                            onChange={handleChange}
                                        />
                                    </Toolbar>
                                    <br/>
                                    <Typography variant="body1" className={classes.input_label}>
                                        PIN
                                    </Typography>
                                    <Toolbar className={classes.fields_toolbar}>
                                        <InputAdornment position="start">
                                            <Icon.Lock style={{color: '#a19f9f'}}/>
                                        </InputAdornment>
                                        <Divider orientation="vertical" flexItem/>
                                        <InputBase
                                            className={classes.input}
                                            placeholder="Enter PIN"
                                            name="pin"
                                            value={state.get('pin')}
                                            type="password"
                                            onChange={handleChange}
                                        />
                                    </Toolbar>
                                    <br/>
                                    <Grid container justify="center">
                                        <Button variant="contained" align="center" size="large"
                                                fullWidth={true} color="primary"
                                                type="submit"
                                                className={classes.primary_button}><b>Login</b>
                                        </Button>
                                    </Grid>
                                </form>
                                <br/><br/>
                                <Grid container justify="center">
                                    <Typography variant="body1">
                                        <Link to='/forgot_pin' className={classes.primary_links}>Forgot PIN</Link>
                                    </Typography>
                                </Grid>
                                <br/>
                                <Grid container justify="center">
                                    <Typography variant="caption">
                                        Powered by <a
                                        href='https://agiza.io/'
                                        target="_blank"
                                        className={classes.primary_links}>
                                        Agiza.io
                                    </a>
                                    </Typography>
                                </Grid>
                                <br/>
                            </Container>

                            {snackbarLoader()}
                            {progressLoader()}
                        </Grid>
                    </Grid>
                </Paper>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(Login);

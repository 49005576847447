import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createMuiTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {grey} from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Cookies from "js-cookie";
import {session_expired} from "../store/modules/auth/actions";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAlert from "@material-ui/lab/Alert";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {authorization_headers} from "../utils/api_authorization";
import {encrypt} from "../utils/crypto_encryption";

const env = runtimeEnv();
const logo_path = '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#640032',
        }
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
        body1: {
            fontSize: '18px',
        }
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    root: {
        flexGrow: 1,
    },
    iconButton: {
        padding: 10,
        color: 'white'
    },
    page_container: {
        marginTop: '90px',
        borderRadius: '8px'
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,

    },
    input_label: {
        marginBottom: '2px'
    },
    orders_date: {
        color: grey[700],
        float: 'right'
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    success_btn: {
        backgroundColor: "#00b89f",
        color: grey[50],
        borderRadius: "20px",
        fontSize: '12px',
    },
    closed_btn: {
        backgroundColor: grey[500],
        borderRadius: "20px",
        fontSize: '12px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    bottom_buttons: {
        fontSize: '18px'
    },
    fields_toolbar: {
        borderColor: '#C9C9C9',
        border: '1px solid',
        borderRadius: '4px',
        paddingLeft: '8px',
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    },
});

function AddAttachments(props) {
    const retailer = useSelector(state => state.retailers.retailer)
    const {classes} = props;
    const history = useHistory();
    const [state, setState] = useState(new Map())
    const dispatch = useDispatch()

    const [loading, setLoading] = React.useState(false)
    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message, setSnackbarMessage] = React.useState(null)

    const upsert = (key, value) => {
        setState((prev) => new Map(prev).set(key, value));
    }

    const getBase64 = (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("Called", reader);
                baseURL = reader.result;
                console.log(baseURL);
                resolve(baseURL);
            };
            console.log(fileInfo);
        });
    };

    const handleChange = (event) => {
        if (event.target.name === 'image') {
            let file = event.target.files[0];

            getBase64(file)
                .then(result => {
                    upsert('file', result)
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            const {name, value} = event.target
            upsert(name, value)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        let params = {
            attachment_type: state.get('attachment_type'),
            image: state.get('file'),
        }

        setLoading(true)

        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/retailers/" + retailer.id + "/add_attachment",
            {
                method: "POST",
                headers: {...authorization_headers(), ...{Authorization: token}},
                body: encrypt(JSON.stringify(params)),
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        setSnackbarMessage("Retailer attachment was uploaded successfully.")
                        setSnackbarSeverity("success")
                        openSnackbar(true)

                        sleep(5000).then(() => {
                            history.push("/retailer_attachments")
                        })
                    } else {
                        setSnackbarMessage("Error uploading attachment. Please retry or contact support.")
                        openSnackbar(true)
                        setLoading(false)
                    }
                }).catch(() => {
                    setSnackbarMessage("Error uploading attachment. Please retry or contact support")
                    openSnackbar(true)
                    setLoading(false)
                })
            })
            .catch(() => {
                setSnackbarMessage("Error uploading attachment. Please retry or contact support.")
                openSnackbar(true)
                setLoading(false)
            })
    }

    const sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time))
    }

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const progressLoader = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        )
    }


    return (
        <React.Fragment>
            <CssBaseline/>
            <ThemeProvider theme={appTheme}>
                <AppBar position="fixed" color="primary" style={{boxShadow: 'none'}}>
                    <Toolbar>
                        <Link to='/retailer_attachments' className={classes.no_decoration}>
                            <IconButton className={classes.iconButton} aria-label="directions"
                                        disableRipple>
                                <ArrowBackIosIcon/>
                            </IconButton>
                        </Link>
                        <Typography className={classes.input} align="center">
                            <b>Add an attachment for {retailer.keid}</b>
                        </Typography>
                        <Link to='/' className={classes.no_decoration}>
                            <Avatar src={logo_path} variant="square" className={classes.square}/>
                        </Link>
                    </Toolbar>
                </AppBar>
                <br/><br/>

                <Container className={classes.page_container} fullWidth>
                    <form onSubmit={handleSubmit}>
                        <Typography variant="body1" className={classes.input_label}>
                            Specify the document type
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <InputLabel>Document Type</InputLabel>
                            <Select
                                value={state.get('attachment_type')}
                                name="attachment_type"
                                label="Attachment type"
                                onChange={handleChange}>
                                <MenuItem value={'registration_cert'}>Registration cert</MenuItem>
                                <MenuItem value={'kra_pin'}>KRA PIN</MenuItem>
                                <MenuItem value={'national_id_side1'}>National ID side1</MenuItem>
                                <MenuItem value={'national_id_side2'}>National ID side2</MenuItem>
                            </Select>
                        </Toolbar>
                        <br/>
                        <Typography variant="body1" className={classes.input_label}>
                            Upload file
                        </Typography>
                        <Toolbar className={classes.fields_toolbar}>
                            <input
                                className={classes.input}
                                placeholder="Image"
                                name="image"
                                value={state.get('image')}
                                type="file"
                                onChange={handleChange}
                            />
                        </Toolbar>

                        <br/>
                        <Grid container justify="center">
                            <Button variant="contained" align="center" size="large"
                                    fullWidth={true}
                                    style={{backgroundColor: '#640032', color: 'white', fontSize: '18px'}}
                                    type="submit"
                                    className={classes.order_now_btn}><b>Upload</b>
                            </Button>
                        </Grid>
                    </form>

                    {snackbarLoader()}
                    {progressLoader()}
                </Container>
            </ThemeProvider>
        </React.Fragment>
    )
}

export default withStyles(styles)(AddAttachments);


import {Redirect} from "react-router-dom";
import React from "react";
import * as Cookies from "js-cookie";
import {login, session_expired} from "../store/modules/auth/actions";
import {useDispatch, useSelector} from "react-redux";
import runtimeEnv from "@mars/heroku-js-runtime-env";
import {authorization_headers} from "../utils/api_authorization";
import {decrypt} from "../utils/crypto_encryption";

export default function ProtectedRoute(props) {
    const Component = props.component
    const env = runtimeEnv()
    const dispatch = useDispatch()
    const signed = useSelector(state => state.auth.signed)

    const validateToken = () => {
        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/agents/validate_token",
            {
                method: "POST",
                headers: {...authorization_headers(), ...{Authorization: token}},
            })
            .then(resp => resp.text())
            .then(resp_data => {
                let data = JSON.parse(decrypt(resp_data))
                if (data.valid === true) {
                    let agents_data = data.agent
                    dispatch(login(agents_data))

                    setCookie(data.token)
                } else {
                    dispatch(session_expired())
                }
            })
            .catch(() => {
                dispatch(session_expired())
            });
    }

    const setCookie = (token) => {
        // Cookie should exipire in 5 minutes
        var expite_at = new Date(new Date().getTime() + 5 * 60 * 1000)
        Cookies.remove('AbsaAgiza')
        Cookies.set('AbsaAgiza', token, {expires: expite_at, secure: true})
    }

    validateToken()

    if (!signed) {
        return <Redirect to="/login"/>
    }

    return <Component/>
}